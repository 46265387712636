import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './Components/ProtectedRoute';
// import Dashboard from './Pages/Dashboard';
// import HomePage from './Pages/HomePage';
// import Invoices from './Pages/Invoices';
import LabsListing from './Pages/Labs';
import Login from './Pages/Login';
import PriceCheck from './Pages/PriceCheck';
// import PrinterComponent from './Pages/Printing';
import Purchasing from './Pages/Purchasing';
import RepairSheet from './Pages/RepairSheet';
import RepairSheetDetail from './Pages/RepairSheetDetail';
// import SalesReport from './Pages/SalesReport';
import Selling from './Pages/Selling';
// import StockReport from './Pages/StockReport';
import UnLocking from './Pages/Unlocking';
import { setAuthenticatedUser } from './Redux-store/userActions';
function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  /**This sets the redux user and token from localstorage use and token when user first time renders the app. */
  useEffect(() => {
    const localStorageToken = localStorage.getItem('token');
    const localStorageUser = JSON.parse(localStorage.getItem('user'));

    if (localStorageToken && localStorageUser) {
      dispatch(setAuthenticatedUser(localStorageUser, localStorageToken));
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <Routes>
            {/* <Route
              path='/sales'
              element={
                <ProtectedRoute>
                  <SalesReport />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              path='/stock'
              element={
                <ProtectedRoute>
                  <StockReport />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              path='/print'
              element={
                <ProtectedRoute>
                  <PrinterComponent />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              path='/invoices'
              element={
                <ProtectedRoute>
                  <Invoices />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path='/buyer/price-check'
              element={
                <ProtectedRoute>
                  <PriceCheck />
                </ProtectedRoute>
              }
            />
            <Route
              path='/buyer/lab-sheet'
              element={
                <ProtectedRoute>
                  <LabsListing />
                </ProtectedRoute>
              }
            />
            <Route
              path='/buyer/repair-sheet/detail'
              element={
                <ProtectedRoute>
                  <RepairSheetDetail />
                </ProtectedRoute>
              }
            />

            <Route
              path='/buyer/repair-sheet'
              element={
                <ProtectedRoute>
                  <RepairSheet />
                </ProtectedRoute>
              }
            />
            <Route
              path='/buyer/purchasing'
              element={
                <ProtectedRoute>
                  <Purchasing />
                </ProtectedRoute>
              }
            />
            <Route
              path='/buyer/selling'
              element={
                <ProtectedRoute>
                  <Selling />
                </ProtectedRoute>
              }
            />
            <Route
              path='/buyer/unlocking'
              element={
                <ProtectedRoute>
                  <UnLocking />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path='/dashboard'
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              path='/homepage'
              element={
                token ? (
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                ) : (
                  <Navigate to='/login' replace />
                )
              }
            /> */}
            <Route
              path='/login'
              element={token ? <Navigate to='/homepage' replace /> : <Login />}
            />
            {/* Catch-all route for handling incorrect routes */}
            <Route path='*' element={<Navigate to='/login' replace />} />
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </>
  );
}

export default App;
