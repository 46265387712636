import { Box, Stack } from '@mui/material';
import axios from 'axios';
import html2canvas from 'html2canvas';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BuyerNavButton } from '../Components/Buttons';
import BuyerHeader from '../Components/Header/BuyerHeader';
import Spinner from '../Components/Spinner';
import '../Styles/RepairSheet/RepairSheet.css';
import { CheckBox, InputBox } from './InputBox';

function RepairSheetDetail() {
  const [activeButton, setActiveButton] = useState('button1');
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const repairId = params.get('repairId');

  const handleInputChange = (fieldName, value) => {};

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/repair/${repairId}?shop=${loggedInUserShopId}`
        );
        const repairData = response.data.data;

        const formattedDate = new Date(
          repairData.returnDate
        ).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

        setFormData({
          shopName: repairData.shopName || '',
          contact: repairData.contact || '',
          password: repairData.password || '',
          invoice: repairData.invoice || '',
          enteredBy: repairData.enteredBy || '',
          sparePhone: repairData.sparePhone || '',
          network: repairData.network || '',
          returnDate: formattedDate,

          customerName: repairData.customerName || '',
          makeAndModel: repairData.makeAndModel || '',
          address: repairData.address || '',
          imei: repairData.imei || '',
          isRefix: repairData.isRefix || false,
          sentToLab: repairData.sentToLab || false,
          faults: repairData.faults || '',
          hasAccessories: repairData.hasAccessories || false,
          hasBattery: repairData.hasBattery || false,
          hasSimCard: repairData.hasSimCard || false,
          hasMemoryCard: repairData.hasMemoryCard || false,
          hasCover: repairData.hasCover || false,
          other: repairData.other || '',
          charges: repairData.charges || '',
          deposit: repairData.deposit || '',
          balance: repairData.balance || '',
          policyConfirm: repairData.policyConfirm || false,
          faultChecklist: repairData.faultChecklist || {},
        });

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching repair data:', error);
        setIsLoading(false);
        toast.error('Error fetching repair data');
      }
    };

    fetchData();
  }, [repairId]);

  const [formData, setFormData] = useState({
    shopName: '',
    contact: '',
    password: '',
    invoice: '',
    enteredBy: '',
    sparePhone: '',
    network: '',
    date: '',
    returnDate: '',
    customerName: '',
    makeAndModel: '',
    address: '',
    imei: '',
    isRefix: false,
    sentToLab: false,
    faults: '',
    hasAccessories: false,
    hasBattery: false,
    hasSimCard: false,
    hasMemoryCard: false,
    hasCover: false,
    other: null,
    charges: '',
    deposit: '',
    balance: '',
    policyConfirm: false,
    faultChecklist: {
      data: { before: false, after: false },
      backCamera: { before: false, after: false },
      frontCamera: { before: false, after: false },
      earpiece: { before: false, after: false },
      buttons: { before: false, after: false },
      chargingPorts: { before: false, after: false },
      mic: { before: false, after: false },
      wifi: { before: false, after: false },
      signalsCalling: { before: false, after: false },
      ringMusic: { before: false, after: false },
      touch: { before: false, after: false },
      lcd: { before: false, after: false },
      sensor: { before: false, after: false },
      headphones: { before: false, after: false },
      loudspeaker: { before: false, after: false },
      repairedBefore: { before: false, after: false },
    },
  });

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const formRef = useRef(null);

  const takeScreenshot = () => {
    html2canvas(formRef.current).then((canvas) => {
      const screenshot = canvas.toDataURL(); // Convert canvas to data URL
      // Display screenshot to user or trigger download
      // Example: display in a new window

      // Create a temporary anchor element
      const anchor = document.createElement('a');
      anchor.href = screenshot;
      anchor.download = 'screenshot.png'; // Specify filename

      // Trigger click event on the anchor element to prompt download
      anchor.click();
    });
  };

  const openEmailClient = () => {
    const emailAddress = 'recipient@example.com';
    const subject = 'Form Submission';
    const body = 'Please find the attached screenshot of the form.';
    const emailLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = emailLink;
  };

  return (
    <>
      <BuyerHeader navs='3' />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gap: '10px',
              alignItems: 'center',
              width: 'calc(100% - 140px)',
              margin: '20px auto 26px auto',
              flexDirection: 'row-reverse',
            }}
          >
            <BuyerNavButton
              text='Send To Email'
              bgcolor='#006B8C'
              color='white'
              onClick={openEmailClient}
            />
            <BuyerNavButton
              text='ScreenShot'
              bgcolor='#006B8C'
              color='white'
              onClick={takeScreenshot}
            />
          </Box>
          <div className='repair-sheet-container' ref={formRef}>
            {activeButton === 'button1' && (
              <>
                <div className='inputs-container'>
                  <div className='inputs-container-upper'>
                    <InputBox
                      text='Shop Name'
                      placeholder='Enter Shop Name'
                      type='text'
                      value={formData.shopName}
                      onChange={(value) => handleInputChange('shopName', value)}
                      disabled={true}
                    />

                    <InputBox
                      text='Date'
                      placeholder='Enter Date'
                      type='text'
                      value={formData.date}
                      disabled={true}
                      onChange={(value) => handleInputChange('date', value)}
                    />
                    <InputBox
                      text='Invoice'
                      placeholder='Enter Invoice No'
                      type='text'
                      value={formData.invoice}
                      onChange={(value) => handleInputChange('invoice', value)}
                      disabled={true}
                    />
                  </div>
                  <div className='inputs-container-upper'>
                    <InputBox
                      text='*Make & Model'
                      placeholder='Enter Make & Model'
                      type='text'
                      value={formData.makeAndModel}
                      onChange={(value) =>
                        handleInputChange('makeAndModel', value)
                      }
                    />

                    <InputBox
                      text='Faults'
                      placeholder=' Faults'
                      type='text'
                      value={formData.faults}
                      onChange={(value) => handleInputChange('faults', value)}
                    />
                    <InputBox
                      text='Network'
                      placeholder='Enter Network'
                      type='text'
                      value={formData.network}
                      onChange={(value) => handleInputChange('network', value)}
                    />
                  </div>

                  <div className='inputs-container-upper'>
                    <InputBox
                      text='Customer Name'
                      placeholder=''
                      type='text'
                      value={formData.customerName}
                      onChange={(value) =>
                        handleInputChange('customerName', value)
                      }
                    />
                    <InputBox
                      text='IMEI'
                      placeholder='Enter Numeric Values'
                      type='text'
                      value={formData.imei}
                      onChange={(value) => handleInputChange('imei', value)}
                    />
                    <InputBox
                      text='* Contact No '
                      placeholder='Enter Contact No'
                      type='text'
                      value={formData.contact}
                      onChange={(value) => handleInputChange('contact', value)}
                    />
                  </div>
                  <div className='inputs-container-upper'>
                    <InputBox
                      text='Password'
                      placeholder='Enter Password'
                      type='password'
                      value={formData.password}
                      onChange={(value) => handleInputChange('password', value)}
                    />
                    <InputBox
                      text='Entered By'
                      placeholder='Enter'
                      type='text'
                      value={formData.enteredBy}
                      onChange={(value) =>
                        handleInputChange('enteredBy', value)
                      }
                    />
                    <InputBox
                      text='Spare Phone'
                      placeholder='Enter'
                      type='text'
                      value={formData.sparePhone}
                      onChange={(value) =>
                        handleInputChange('sparePhone', value)
                      }
                    />
                  </div>
                  <div className='inputs-container-upper'>
                    <div className='input-box'>
                      <label>Notes</label>
                      <textarea
                        style={{ minHeight: '180px' }}
                        value={formData.address}
                        onChange={(e) =>
                          handleInputChange('address', e.target.value)
                        }
                      />
                    </div>
                    <div className='column-container'>
                      <InputBox
                        text='Exp.Return'
                        placeholder='Enter'
                        type='text'
                        value={formData.date}
                        onChange={(value) =>
                          handleInputChange('returnDate', value)
                        }
                      />
                      <div className='input-box'>
                        <label>Sent To Lab</label>
                        <div
                          style={{
                            display: 'flex',
                            gap: '37px',
                            marginTop: '13px',
                          }}
                        >
                          <CheckBox
                            text={'Yes'}
                            checked={formData.sentToLab}
                            onChange={() => {
                              handleInputChange('sentToLab', true);
                            }}
                          />
                          <CheckBox
                            text={'No'}
                            checked={!formData.sentToLab}
                            onChange={() => {
                              handleInputChange('sentToLab', false);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='column-container'>
                      <div className='input-box'>
                        <label>Refix</label>
                        <div
                          style={{
                            display: 'flex',
                            gap: '37px',
                            marginTop: '13px',
                          }}
                        >
                          <CheckBox
                            text={'Yes'}
                            checked={formData.isRefix}
                            onChange={() => handleInputChange('isRefix', true)}
                          />
                          <CheckBox
                            text={'No'}
                            checked={!formData.isRefix}
                            onChange={() => handleInputChange('isRefix', false)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='inputs-container-bottom'>
                    <CheckBox
                      text={'Accessories'}
                      checked={formData.hasAccessories}
                      disabled={true}
                      onChange={(checked) =>
                        handleInputChange('hasAccessories', checked)
                      }
                    />
                    <CheckBox
                      text={'Battery'}
                      checked={formData.hasBattery}
                      disabled={true}
                      onChange={(checked) =>
                        handleInputChange('hasBattery', checked)
                      }
                    />
                    <CheckBox
                      text={'Sim Card'}
                      checked={formData.hasSimCard}
                      disabled={true}
                      onChange={(checked) =>
                        handleInputChange('hasSimCard', checked)
                      }
                    />
                    <CheckBox
                      text={'Memory Card'}
                      checked={formData.hasMemoryCard}
                      disabled={true}
                      onChange={(checked) =>
                        handleInputChange('hasMemoryCard', checked)
                      }
                    />
                    <CheckBox
                      text={'Cover'}
                      checked={formData.hasCover}
                      disabled={true}
                      onChange={(checked) =>
                        handleInputChange('hasCover', checked)
                      }
                    />
                    {/* <CheckBox
                    text={"Other"}
                    checked={formData.hasOther}
                    onChange={(checked) =>
                      handleInputChange("hasOther", checked)
                    }
                  /> */}

                    <input
                      type='text'
                      className='input-foam'
                      disabled={true}
                      value={formData.other}
                    />
                  </div>
                  <div className='inputs-container-upper'>
                    <div className='balance-container'>
                      <p>Charges</p>
                      <input
                        // disabled
                        min={0}
                        type='number'
                        className='input-foam'
                        disabled={true}
                        value={formData.charges}
                      />
                    </div>
                    <div className='balance-container'>
                      <p>Deposit</p>
                      <input
                        // disabled
                        min={0}
                        type='number'
                        className='input-foam'
                        disabled={true}
                        value={formData.deposit}
                      />
                    </div>
                    <div className='balance-container'>
                      <p>Balance</p>
                      <input
                        type='number'
                        className='input-foam'
                        disabled={true}
                        style={{ background: '#D9D9D9' }}
                        value={formData.balance}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default RepairSheetDetail;
