import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Images/login.png';
import '../../Styles/Header/Header.css';
import { BuyerNavButton } from '../Buttons';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../Redux-store/userActions';

function BuyerHeader({ navs }) {
  const [nav, setNav] = useState(navs);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/buyer/price-check') {
      setNav('1');
    } else if (window.location.pathname === '/buyer/lab-sheet') {
      setNav('2');
    } else if (window.location.pathname === '/buyer/repair-sheet') {
      setNav('3');
    } else if (window.location.pathname === '/buyer/purchasing') {
      setNav('4');
    } else if (window.location.pathname === '/buyer/selling') {
      setNav('5');
    } else if (window.location.pathname === '/buyer/unlocking') {
      setNav('6');
    }
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className='header'>
      <div className='upper'>
        <img src={logo} />

        <div className='nav-container' style={{ margin: '0px' }}>
          <Link to='/buyer/price-check'>
            {' '}
            {navs == 1 ? (
              <BuyerNavButton
                text='Price Check'
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <BuyerNavButton text='Price Check' bgcolor='white' color='#000' />
            )}
          </Link>
          <Link to='/buyer/lab-sheet'>
            {' '}
            {navs == 2 ? (
              <BuyerNavButton
                text='Labs'
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <BuyerNavButton text='Labs' bgcolor='#fff' color='#000' />
            )}
          </Link>
          <Link to='/buyer/repair-sheet'>
            {' '}
            {navs == 3 ? (
              <BuyerNavButton
                text='Repair Sheet'
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <BuyerNavButton text='Repair Sheet' bgcolor='#fff' color='#000' />
            )}
          </Link>
          <Link to='/buyer/purchasing'>
            {navs == 4 ? (
              <BuyerNavButton
                text='Purchasing'
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <BuyerNavButton text='Purchasing' bgcolor='#fff' color='#000' />
            )}
          </Link>
          <Link to='/buyer/selling'>
            {navs == 5 ? (
              <BuyerNavButton
                text='Selling'
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <BuyerNavButton text='Selling' bgcolor='#fff' color='#000' />
            )}{' '}
          </Link>
          <Link to='/buyer/unlocking'>
            {navs == 6 ? (
              <BuyerNavButton
                text='Unlocking'
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <BuyerNavButton text='Unlocking' bgcolor='#fff' color='#000' />
            )}{' '}
          </Link>
        </div>
        {/* <div className="shop-box">
          <h3>Shop#1 </h3>
          <div className="circle"></div>
        </div> */}
      </div>

      {nav === '1' && (
        <div className='lower-nav'>
          <BuyerNavButton
            text='Logout'
            bgcolor='#006B8C'
            color='white'
            onClick={handleLogout}
          />
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <h4>Price Check</h4>
          </div>
        </div>
      )}
      {nav === '2' && (
        <div className='lower-nav'>
          <BuyerNavButton
            text='Logout'
            bgcolor='#006B8C'
            color='white'
            onClick={handleLogout}
          />
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <h4>Labs</h4>
          </div>
        </div>
      )}
      {nav === '3' && (
        <div className='lower-nav'>
          <BuyerNavButton
            text='Logout'
            bgcolor='#006B8C'
            color='white'
            onClick={handleLogout}
          />
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <h4>Repair Sheet</h4>
          </div>
        </div>
      )}
      {nav === '4' && (
        <div className='lower-nav'>
          <BuyerNavButton
            text='Logout'
            bgcolor='#006B8C'
            color='white'
            onClick={handleLogout}
          />
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <h4>Purchasing</h4>
          </div>
        </div>
      )}
      {(nav === '5' || nav === '6') && (
        <div className='lower-nav'>
          <BuyerNavButton
            text='Logout'
            bgcolor='#006B8C'
            color='white'
            onClick={handleLogout}
          />
          <div style={{ display: 'flex', gap: '20px' }}>
            <h4>{nav === '5' ? 'Selling' : 'Unlocking'}</h4>
          </div>
        </div>
      )}
    </div>
  );
}

export default BuyerHeader;
