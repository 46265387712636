import axios from 'axios';
import html2canvas from 'html2canvas';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import BuyerHeader from '../Components/Header/BuyerHeader';
import Spinner from '../Components/Spinner';
import '../Styles/Purchasing/Purchasing.css';
import { CheckBox, InputBox } from './InputBox';
function Purchasing() {
  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    shopName: '',
    shop: loggedInUserShopId,
    contact: '',
    password: '',
    invoice: '',
    price: '',
    model: '',
    date: '',
    retailer: '',
    note: '',
    imei: '',
    reference: '',
    accessories: false,
    box: false,
    charger: false,
    headphone: false,
    otherText: null,
    appleIdRemoved: false,
    purchasedBy: '',
  });

  // Function to handle form submission
  const handleSubmit = async () => {
    setSubmitted(true);
    const {
      invoice,
      otherText,
      policyConfirm,
      policyConfirm3,
      policyConfirm2,
      ...formDataWithoutInvoice
    } = formData;

    // Set otherText to null if it is empty
    const modifiedFormData = {
      ...formDataWithoutInvoice,
      otherText: otherText?.trim() === '' ? null : otherText,
    };
    if (!formData.policyConfirm) {
      setSubmitted(false);
      return toast.error('Please Accept All Terms & Conditions');
    } else if (!formData.policyConfirm2) {
      setSubmitted(false);
      return toast.error('Please Accept All Terms & Conditions');
    } else if (!formData.policyConfirm3) {
      setSubmitted(false);
      return toast.error('Please Accept All Terms & Conditions');
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchasing?shop=${loggedInUserShopId}`,
        modifiedFormData
      );
      setFormData({
        shopName: '',
        contact: '',
        password: '',
        invoice: '',
        price: '',
        model: '',
        date: '',
        retailer: '',
        note: '',
        imei: '',
        identification: '',
        reference: '',
        accessories: false,
        box: false,
        charger: false,
        headphone: false,
        otherText: null,
        appleIdRemoved: false,
        purchasedBy: '',
        network: '',
        policyConfirm: false,
        policyConfirm2: false,
        policyConfirm3: false,
      });
      toast.success('Purchasing has been made successfully!');
    } catch (error) {
      console.error('Error while sending form data: ', error);
      setSubmitted(false);
      // Check if the error response contains a message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`Error: ${error.response.data.message}`);
        setSubmitted(false);
      } else {
        toast.error('Error while sending purchasing data!');
        setSubmitted(false);
      }
    }
  };

  // Function to handle form input changes
  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  const datePickerStyles = {
    input: {
      flex: '1 1',
      background: '#f5f5f5',
      padding: '8px', // Adjust padding as needed
      borderRadius: '10px',
      outline: 'none',
      color: '#444',
      fontFamily: 'Inter Tight',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    // Add more styles as needed
  };

  useEffect(() => {
    // Check if invoice is not already set (i.e., it's the first render)
    const currentDate = new Date();

    // Get the components of the date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    // Construct the formatted date
    const formattedDate = `${year}-${month}-${day}`;

    const user = JSON.parse(localStorage.getItem('user'));
    if (!formData.invoice) {
      // Generate a 6-digit unique invoice number
      const invoiceNumber = Math.floor(100000 + Math.random() * 900000);
      setFormData((prevData) => ({
        ...prevData,
        invoice: invoiceNumber.toString(),
        date: formattedDate,
        shopName: user.name,
        purchasedBy: user._id,
      }));
    }
  }, [formData.invoice]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the Base64 string in formData
        setFormData((prevFormData) => ({
          ...prevFormData,
          identification: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    setFormData((prevFormData) => ({
      ...prevFormData,
      identification: null,
    }));
  };

  return (
    <>
      <BuyerHeader navs='4' />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='repair-sheet-container'>
          <div className='inputs-container'>
            <div className='inputs-container-upper'>
              <InputBox
                text='Shop Name'
                placeholder='Enter Shop Name'
                type='text'
                value={formData.shopName}
                onChange={(value) => handleInputChange('shopName', value)}
                disabled={true}
              />
              <InputBox
                text='Date'
                placeholder='Enter Date'
                type='text'
                value={formData.date}
                disabled={true}
                onChange={(value) => handleInputChange('date', value)}
              />
              <InputBox
                text='Invoice'
                placeholder='Enter Invoice No'
                type='text'
                value={formData.invoice}
                onChange={(value) => handleInputChange('invoice', value)}
                disabled={true}
              />
            </div>
            <div className='inputs-container-upper'>
              <InputBox
                text='*Make'
                placeholder='Select'
                type='text'
                value={formData.make}
                onChange={(value) => handleInputChange('make', value)}
              />
              <InputBox
                text='*Model'
                placeholder='Enter'
                type='text'
                value={formData.model}
                onChange={(value) => handleInputChange('model', value)}
              />
              <InputBox
                text='*IMEI'
                placeholder='Enter IMEI'
                type='text'
                value={formData.imei}
                onChange={(value) => handleInputChange('imei', value)}
              />
            </div>
            <div className='inputs-container-upper'>
              <InputBox
                text='Customer Name'
                placeholder=''
                type='text'
                value={formData.customerName}
                onChange={(value) =>
                  handleInputChange('customerName', value || undefined)
                }
              />
              <InputBox
                text='Password'
                placeholder='Enter Password'
                type='password'
                value={formData.password}
                onChange={(value) => handleInputChange('password', value)}
              />
              <InputBox
                text='*Contact No'
                placeholder='Enter Contact No'
                type='text'
                value={formData.contact}
                onChange={(value) => handleInputChange('contact', value)}
              />
            </div>
            <div className='inputs-container-upper'>
              <InputBox
                text='Notable Defects'
                placeholder='Enter Defects'
                type='text'
                value={formData.defect}
                onChange={(value) => handleInputChange('defect', value)}
              />
              <InputBox
                text='Network'
                placeholder='Enter Network'
                type='text'
                value={formData.netwrok}
                onChange={(value) => handleInputChange('network', value)}
              />
              <InputBox
                text='*Price'
                placeholder='Enter price'
                type='text'
                value={formData.price}
                onChange={(value) => handleInputChange('price', value)}
              />
            </div>
            <div className='inputs-container-upper'>
              <div className='input-box'>
                <label>Address</label>
                <textarea
                  style={{ minHeight: '153px' }}
                  value={formData.note}
                  onChange={(e) => handleInputChange('note', e.target.value)}
                />
              </div>
              <div className='column-container'>
                <InputBox
                  text='*Retailer'
                  placeholder='Enter'
                  type='text'
                  value={formData.retailer}
                  onChange={(value) => handleInputChange('retailer', value)}
                />
                <InputBox
                  text='Reference'
                  placeholder='Enter Reference'
                  type='text'
                  value={formData.reference}
                  onChange={(value) => handleInputChange('reference', value)}
                />
              </div>
              <div className='column-container'>
                <div className='input-box'>
                  <label>Identification</label>
                  <div className='ester'>
                    <label
                      htmlFor='file-upload'
                      className='custom-file-upload'
                    ></label>
                    <input
                      id='file-upload'
                      type='file'
                      accept='.png, .jpg, .jpeg, .svg'
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <span className='ester-er'>
                      {formData.identification
                        ? selectedFile?.name
                        : 'No file chosen'}
                    </span>
                    {formData.identification ? (
                      <button
                        onClick={removeFile}
                        style={{ border: 'none', background: 'transparent' }}
                      >
                        ❌
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className='inputs-container-bottom'>
              <CheckBox
                text={'Accessories'}
                checked={formData.accessories}
                onChange={(value) => handleInputChange('accessories', value)}
              />
              <CheckBox
                text={'Box'}
                checked={formData.box}
                onChange={(value) => handleInputChange('box', value)}
              />
              <CheckBox
                text={'Charger'}
                checked={formData.charger}
                onChange={(value) => handleInputChange('charger', value)}
              />
              <CheckBox
                text={'Headphone'}
                checked={formData.headphone}
                onChange={(value) => handleInputChange('headphone', value)}
              />
              <CheckBox
                text={'Other'}
                checked={formData.otherText}
                onChange={(value) => handleInputChange('otherText', value)}
              />

              <input
                type='text'
                className='input-foam'
                onChange={(e) => handleInputChange('otherText', e.target.value)}
              />
            </div>
          </div>
          <div className='bottom-sheet-containers'>
            <div className='sheet-right'>
              <p>
                The trade-in services are operated by Freedom Phones (US, WE).
                Nothing in these terms and conditions shall affect your
                statutory rights. These Conditions are governed by English Law
                and the courts of England shall have exclusive jurisdiction to
                settle any dispute or claim arising out of or in connection with
                these Conditions. By signing this agreement YOU, the customer,
                agree to the following conditions:
              </p>

              <h3>1. Your Information</h3>
              <ul>
                <li>
                  <p>
                    You consent to us passing your information (including name,
                    address, telephone number, email address, Device
                    make/model/IMEl/network) in order for us to process your
                    trade in and contact you and the network in relation to
                    unlocking of the Device only.
                  </p>
                </li>
                <li>
                  <p>
                    Data stored on the Device that you wish to retain must be
                    saved elsewhere and you must remove any memory card and/or
                    all data that has been put onto the Device prior to trade
                    in. We will not be liable for any damage, loss, or erasure
                    of any such data or for any consequences of you not removing
                    your data or memory card, including use or disclosure of
                    such data.
                  </p>
                </li>
                <li>
                  <p>
                    If the Device contains a SIM card, you must remove this
                    along with any accessories prior to trade in. We will not be
                    liable for any consequences of you not removing the SIM card
                    or accessories, including any payments associated with the
                    Device or the SIM card.
                  </p>
                </li>
              </ul>

              <h3>2. Change of Ownership</h3>
              <p>
                By signing and agreeing to the terms of this agreement and;
                accepting our pre-agreed trade-in payout sum and; by handing us
                your goods, the goods will be deemed to be property of Freedom
                Phones and all rights over the goods will be transferred to us.
              </p>

              <h3 style={{ marginBottom: '10px' }}>3. The Goods or Device</h3>
              <ul>
                <li>
                  <p>
                    You confirm that you are either the owner of the Device or
                    Goods or you have obtained express permission from the
                    rightful owner to trade in The Goods.
                  </p>
                </li>
                <li>
                  <p>
                    The Goods must not be stolen or listed with us or a third
                    party as stolen. If The Goods fail any due diligence check
                    we may notify the relevant police authority and we may pass
                    The Goods and your details to them and we quoted value will
                    not be paid to you.
                  </p>
                </li>
                <li>
                  <p>
                    Freedom Phones may seek compensation in full against you for
                    any loss, damage or expense incurred by you for inaccurate
                    information regarding the goods you have sold to us.
                  </p>
                </li>
                <li>
                  <p>
                    The Goods are not subject to hire purchase, rental agreement
                    or any other loan/charge that may result in the loss of
                    these goods from us.
                  </p>
                </li>
                <li>
                  <p>
                    The Goods are in fully working order and are free from any
                    defects or damage (unless we are accepting your goods in a
                    faulty condition)
                  </p>
                </li>
              </ul>

              <h3>4. Returns</h3>
              <p>
                Once you have traded in The Goods, it will not be returned to
                you under any circumstances.
              </p>
              <h3 style={{ marginBottom: '0' }}>Customer's Declaration</h3>
            </div>
            <div className='checkbox-conditions'>
              <CheckBox
                text='I confirm that I have read and understood the terms and conditions of Freedom Phones Trade-In Agreement set out above points 1, 2, 3 and 4.'
                checked={formData.policyConfirm}
                onChange={(checked) =>
                  handleInputChange('policyConfirm', checked)
                }
              />
              <CheckBox
                text=' I confirm that all statements and information provided are accurate and true.'
                checked={formData.policyConfirm3}
                onChange={(checked) =>
                  handleInputChange('policyConfirm3', checked)
                }
              />

              <CheckBox
                text='I confirm that I am over 18 years old.'
                checked={formData.policyConfirm2}
                onChange={(checked) =>
                  handleInputChange('policyConfirm2', checked)
                }
              />
            </div>
          </div>
          <div
            className='bottom-sheet-container'
            style={{ justifyContent: 'space-between' }}
          >
            <CheckBox
              text={
                'I confirm that I have read and understood the terms and conditions given above.'
              }
              checked={formData.appleIdRemoved}
              onChange={(value) => handleInputChange('appleIdRemoved', value)}
            />
            <button
              className='submit'
              onClick={handleSubmit}
              disabled={submitted}
              style={{ margin: '0' }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Purchasing;
